/**
 * @generated SignedSource<<0559e36db12224759499a022f83483e9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type hooksAmpRedirectArticle$data = {
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckIsAmpEnabledArticle">;
  readonly " $fragmentType": "hooksAmpRedirectArticle";
};
export type hooksAmpRedirectArticle$key = {
  readonly " $data"?: hooksAmpRedirectArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"hooksAmpRedirectArticle">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "SectionSegment",
  "kind": "LinkedField",
  "name": "sections",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Section",
      "kind": "LinkedField",
      "name": "value",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "hooksAmpRedirectArticle",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersCheckIsAmpEnabledArticle",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ArticleTypeSegment",
          "kind": "LinkedField",
          "name": "types",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ArticleType",
              "kind": "LinkedField",
              "name": "value",
              "plural": true,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "disableOffPlatformContent",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sponsorType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "published",
          "storageKey": null
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "helpersCheckIsPlusArticle",
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PaywallType",
                      "kind": "LinkedField",
                      "name": "paywallTypes",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "entityUuid",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "Article",
                  "abstractKey": null
                }
              ],
              "type": "Content",
              "abstractKey": "__isContent"
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "helpersCheckIsPostiesArticle",
          "selections": [
            (v2/*: any*/)
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();

(node as any).hash = "cba5f4cccd60ef30f4f54398b7a7fe0d";

export default node;
