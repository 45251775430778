import { formatInTimeZone, notEmpty, theme, useResponsive } from "@product/scmp-sdk";
import format from "date-fns/format";
import { useAtomValue } from "jotai";
import first from "lodash/first";
import { useEffect, useState } from "react";
import { graphql, readInlineData, useFragment } from "react-relay";

import { setContentInterest } from "shared/components/tracking/mirror";
import { acknowledgementGateArticleIds } from "shared/data";

import { ArticleAcknowledgementGateOverlay } from "scmp-app/components/article/article-acknowledgement-gate-overlay";
import { checkIsAmpEnabledArticle } from "scmp-app/components/article/article-seo/helpers";
import { trackingAtom } from "scmp-app/components/tracking";
import { appAtom } from "scmp-app/lib/app/atoms";
import type { hooksAmpRedirectArticle$key } from "scmp-app/queries/__generated__/hooksAmpRedirectArticle.graphql";
import type { hooksArticleAcknowledgementGateOverlayArticle$key } from "scmp-app/queries/__generated__/hooksArticleAcknowledgementGateOverlayArticle.graphql";
import type { hooksContentInterestArticle$key } from "scmp-app/queries/__generated__/hooksContentInterestArticle.graphql";
import type { hooksTrackPageViewArticle$key } from "scmp-app/queries/__generated__/hooksTrackPageViewArticle.graphql";

import { UrlSectionNameMap } from "./consts";
import type { ShareContentUnlocked } from "./types";

type Sentiment = Record<string, unknown> & {
  readability_school_level: string[];
  sentiment_category: string;
};

export const useArticleAcknowledgementGateOverlay = (
  article_: hooksArticleAcknowledgementGateOverlayArticle$key,
) => {
  const { entityId: currentArticleId } = useFragment(
    graphql`
      fragment hooksArticleAcknowledgementGateOverlayArticle on Article {
        entityId
      }
    `,
    article_,
  );

  const [acknowledgementAccepted, setAcknowledgementAccepted] = useState(false);
  const isAcknowledgementRequired =
    acknowledgementGateArticleIds.has(currentArticleId) && !acknowledgementAccepted;

  const renderAcknowledgementGateOverlay = () => (
    <>
      {isAcknowledgementRequired && (
        <ArticleAcknowledgementGateOverlay
          handleAcceptAcknowledgement={() => setAcknowledgementAccepted(true)}
        />
      )}
    </>
  );

  return {
    isAcknowledgementRequired,
    renderAcknowledgementGateOverlay,
  };
};

export const useTrackContentInterest = (article_: hooksContentInterestArticle$key | undefined) => {
  useEffect(() => {
    if (!notEmpty(article_)) return;

    const article = readInlineData(
      graphql`
        fragment hooksContentInterestArticle on Article @inline {
          entityId
          sentiment
          urlAlias
          types {
            value {
              name
            }
          }
          topics {
            name
          }
        }
      `,
      article_,
    );

    const [mainType] = first(article?.types ?? [])?.value ?? [];
    const contentType = `Article - ${mainType?.name ?? "Standard"}`;

    const sentiment = (article?.sentiment ?? {}) as Sentiment;
    const readabilitySchoolLevel = sentiment?.readability_school_level ?? [];
    const sentimentCategory = sentiment?.sentiment_category ?? "";
    const contentTopics =
      article?.topics?.map?.(topic => topic?.name ?? "").filter?.(name => notEmpty(name)) ?? [];

    const matchedPath =
      Object.keys(UrlSectionNameMap).find(path => article?.urlAlias?.startsWith?.(path)) ?? "";
    const sectionName = UrlSectionNameMap[matchedPath] ?? "";

    setContentInterest({
      article_id: article.entityId,
      content_topics: contentTopics,
      content_type: contentType,
      readability_school_level: readabilitySchoolLevel,
      sentiment_category: sentimentCategory,
      site_verticals: sectionName,
    });
  }, [article_]);
};

export const useTrackPageView = (
  article_: hooksTrackPageViewArticle$key,
  shareContentUnlocked?: ShareContentUnlocked,
  overriddenPath?: string,
) => {
  const article = useFragment(
    graphql`
      fragment hooksTrackPageViewArticle on Article {
        entityId
        publishedDate
        headline
        sections {
          value {
            name
          }
        }
        urlAlias
        authors {
          name
        }
        entityUuid
      }
    `,
    article_,
  );

  const { trackPageView } = useAtomValue(trackingAtom);

  useEffect(() => {
    if (!notEmpty(trackPageView)) {
      return;
    }

    const { authors, entityId, entityUuid, headline, publishedDate, sections, urlAlias } = article;

    const sanitizedAuthors = authors?.map(author => author?.name).join(", ");

    const joinedSectionNames = sections?.[0]?.value?.map(section => section?.name).join(", ");

    const shareTracking =
      shareContentUnlocked?.entityId === article.entityId
        ? {
            free_article_start_date: format(shareContentUnlocked?.shareTime ?? 0, "yyyy-MM-dd"),
            referral_users_id: shareContentUnlocked?.fcmId,
            referral_users_login_id: shareContentUnlocked?.userId,
          }
        : {};

    trackPageView({
      articleID: entityId,
      authors: sanitizedAuthors,
      contentType: "Article",
      entityId,
      entityUuid,
      page: "Article",
      path: overriddenPath ?? urlAlias,
      primarySection: first(sections?.[0]?.value)?.name ?? "",
      publishedDate: publishedDate ? formatInTimeZone(publishedDate, "dd MMM yyyy") : null,
      sections: joinedSectionNames,
      webHeadline: headline ?? "",
      ...shareTracking,
    });
  }, [trackPageView, article, shareContentUnlocked, overriddenPath]);
};

/**
 * Workaround for INP to redirect india users to AMP
 */
export const useAmpRedirect = (article_: hooksAmpRedirectArticle$key) => {
  const article = useFragment(
    graphql`
      fragment hooksAmpRedirectArticle on Article {
        ...helpersCheckIsAmpEnabledArticle
      }
    `,
    article_,
  );
  const isAmpEnabled = checkIsAmpEnabledArticle(article);
  const { navigatorInfo } = useAtomValue(appAtom);
  const isMobile = useResponsive(theme.breakpoints.only("mobile"), false);

  useEffect(() => {
    if (!isAmpEnabled || !isMobile) return;
    else if (!["IN"].includes((navigatorInfo?.country ?? "").toUpperCase())) return;

    window.location.href = window.location.href
      .replaceAll("www.", "")
      .replaceAll("https://", "https://amp.");
  }, [isAmpEnabled, isMobile, navigatorInfo?.country]);
};
