import first from "lodash/first";
import { graphql, readInlineData } from "react-relay";

import { article as articleData, section as sectionData } from "shared/data";

import { checkIsPlusArticle } from "scmp-app/components/article/article-render/plus/helpers";
import { checkIsPostiesArticle } from "scmp-app/components/article/article-render/posties/helpers";
import type { helpersCheckIsAmpEnabledArticle$key } from "scmp-app/queries/__generated__/helpersCheckIsAmpEnabledArticle.graphql";

import { ExcludedArticleIds } from "./consts";

export const checkIsAmpEnabledArticle = (reference: helpersCheckIsAmpEnabledArticle$key) => {
  const article = readInlineData(
    graphql`
      fragment helpersCheckIsAmpEnabledArticle on Article @inline {
        entityId
        types {
          value {
            entityId
          }
        }
        sections {
          value {
            entityId
          }
        }
        disableOffPlatformContent
        sponsorType
        published
        ...helpersCheckIsPlusArticle
        ...helpersCheckIsPostiesArticle
      }
    `,
    reference,
  );

  const baseArticleTypeData = article.types?.flatMap(type => type?.value) ?? [];

  const baseArticleSectionData = article.sections?.flatMap(section => section?.value) ?? [];

  const isNotSponsorType = !article.sponsorType;

  const isPublished = article.published;

  const isNotInfographicsArticle = !(
    baseArticleSectionData.some(
      section => section?.entityId === sectionData.infographics.entityId,
    ) || baseArticleTypeData.some(type => type?.entityId === articleData.types.infographic.entityId)
  );

  const isNotSeriesArticle =
    first(baseArticleTypeData)?.entityId !== articleData.types.series.entityId;

  const isNotNewsletterTypeArticle =
    first(baseArticleTypeData)?.entityId !== articleData.types.newsletter.entityId;

  const isNotExcludedArticle = !ExcludedArticleIds.includes(article.entityId);

  const isNotAnnouncementArticle = article.entityId !== articleData.articles.announcement.entityId;

  const isNotDisableAmp = !(article.disableOffPlatformContent ?? []).includes("amp");

  const isNotArticleLiveType =
    first(baseArticleTypeData)?.entityId !== articleData.types.live.entityId;

  const isNotPlusArticle = !checkIsPlusArticle(article);

  const isNotPostiesArticle = !checkIsPostiesArticle(article);

  return (
    isNotSponsorType &&
    isPublished &&
    isNotInfographicsArticle &&
    isNotSeriesArticle &&
    isNotNewsletterTypeArticle &&
    isNotExcludedArticle &&
    isNotAnnouncementArticle &&
    isNotDisableAmp &&
    isNotArticleLiveType &&
    isNotPlusArticle &&
    isNotPostiesArticle
  );
};
