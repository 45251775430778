import { findFirstNonEmptyString } from "@product/scmp-sdk";
import first from "lodash/first";
import { useCallback, useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { Title } from "shared/components/meta/consts";
import { article as articleData, author } from "shared/data";
import { useCanonicalUrl } from "shared/hooks";

import { checkIsPostMagazineArticle } from "scmp-app/components/article/article-render/post-magazine-article/helper";
import { checkIsPostiesArticle } from "scmp-app/components/article/article-render/posties/helpers";
import { checkIsStyleArticle } from "scmp-app/components/article/article-render/style-article/helper";
import { useDetermineHeadlineTag } from "scmp-app/components/content/content-headline/content-headline-tag/hooks";
import { PostiesTitle } from "scmp-app/components/posties/posties-seo/consts";
import type { articleSeoArticle$data } from "scmp-app/queries/__generated__/articleSeoArticle.graphql";
import type { hooksArticleSeoArticle$key } from "scmp-app/queries/__generated__/hooksArticleSeoArticle.graphql";

import { checkIsAmpEnabledArticle } from "./helpers";

export const useArticleSeoHelper = (article_: articleSeoArticle$data) => {
  const article = useFragment<hooksArticleSeoArticle$key>(
    graphql`
      fragment hooksArticleSeoArticle on Article {
        ...helpersCheckIsPostiesArticle
        ...helpersCheckIsPostMagazineArticle
        ...helpersCheckIsStyleArticle
        ...hooksContentHeadlineTagContent
        ...helpersCheckIsAmpEnabledArticle
        entityId
        headline
        socialHeadline
        types {
          value {
            entityId
          }
        }
        authors {
          entityId
        }
        images {
          generic_og: style(filter: { style: "og_image_scmp_generic" }) {
            url
          }
          styles(
            filter: {
              styles: [
                "og_image_posties_article"
                "og_postmag"
                "og_image_style"
                "og_image_scmp_analysis"
                "og_image_scmp_explainer"
                "og_image_scmp_editorial"
                "og_image_scmp_fact_check"
                "og_image_scmp_debate"
                "og_image_scmp_live"
                "og_image_scmp_obituary"
                "og_image_scmp_opinion"
                "og_image_scmp_review"
                "og_image_scmp_series"
                "og_image_scmp_generic"
              ]
            }
          ) {
            url
            style
          }
          type
        }
        urlAlias
      }
    `,
    article_,
  );

  const { name } = useDetermineHeadlineTag(article, false) ?? {};
  const computeMetaHeadline = useCallback(
    (headline: string) => (name ? `${name} | ${headline}` : headline),
    [name],
  );

  const computedMetaHeadline = useMemo(
    () => computeMetaHeadline(article.headline ?? ""),
    [article.headline, computeMetaHeadline],
  );

  const computedOpenGraphHeadline = useMemo(
    () => computeMetaHeadline(findFirstNonEmptyString(article.socialHeadline, article.headline)),
    [article.headline, article.socialHeadline, computeMetaHeadline],
  );

  const isPostiesArticle = useMemo(() => checkIsPostiesArticle(article), [article]);

  const isPostMagazineArticle = useMemo(() => checkIsPostMagazineArticle(article), [article]);

  const isStyleArticle = useMemo(() => checkIsStyleArticle(article), [article]);

  const isAmpMetaTagRequired = useMemo(() => checkIsAmpEnabledArticle(article), [article]);

  const ogMetaDataImage = useMemo(() => {
    const articleTypeId = first(article.types?.flatMap(type => type?.value))?.entityId ?? "";

    const isScmpEditorialAuthor =
      first(article.authors)?.entityId === author.scmpEditorial.entityId;
    const newMap = {
      [articleData.types.analysis.entityId]: "_analysis",
      [articleData.types.column.entityId]: isScmpEditorialAuthor ? "_editorial" : "_opinion",
      [articleData.types.debate.entityId]: "_debate",
      [articleData.types.explainer.entityId]: "_explainer",
      [articleData.types.factCheck.entityId]: "_face_check",
      [articleData.types.live.entityId]: "_live",
      [articleData.types.obituary.entityId]: "_obituary",
      [articleData.types.opinion.entityId]: isScmpEditorialAuthor ? "_editorial" : "_opinion",
      [articleData.types.review.entityId]: "_review",
      [articleData.types.series.entityId]: "_series",
    } as Record<string, string>;

    const validImageSet =
      article.images?.find(imageSet => imageSet?.type === "default") ?? article.images?.[0];

    const metaImage = validImageSet?.styles?.find(set => {
      if (isPostiesArticle && set?.style === "og_image_posties_article") {
        return set;
      }
      if (isPostMagazineArticle && set?.style === "og_postmag") {
        return set;
      }
      if (isStyleArticle && set?.style === "og_image_style") {
        return set;
      }
      if (set?.style?.includes(newMap[articleTypeId]) && set?.style?.includes("og_image_")) {
        return set;
      }
    });

    return metaImage?.url ?? validImageSet?.generic_og?.url ?? "";
  }, [
    article.authors,
    article.images,
    article.types,
    isPostiesArticle,
    isPostMagazineArticle,
    isStyleArticle,
  ]);

  const { canonicalUrl } = useCanonicalUrl(article.urlAlias);

  const openGraphMetaData = useMemo(
    () => ({
      images: [{ url: ogMetaDataImage }],
      siteName: isPostiesArticle ? PostiesTitle : Title,
      title: computedOpenGraphHeadline,
      type: "article",
      url: canonicalUrl,
    }),
    [canonicalUrl, computedOpenGraphHeadline, isPostiesArticle, ogMetaDataImage],
  );

  return {
    canonicalUrl,
    computedMetaHeadline,
    isAmpMetaTagRequired,
    openGraphMetaData,
  };
};
