/**
 * @generated SignedSource<<11f3a2dc6dfd6e1b673a60c179db2c23>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type hooksArticleSeoArticle$data = {
  readonly authors: ReadonlyArray<{
    readonly entityId: string;
  } | null | undefined> | null | undefined;
  readonly entityId: string;
  readonly headline: string | null | undefined;
  readonly images: ReadonlyArray<{
    readonly generic_og: {
      readonly url: string | null | undefined;
    } | null | undefined;
    readonly styles: ReadonlyArray<{
      readonly style: string | null | undefined;
      readonly url: string | null | undefined;
    } | null | undefined> | null | undefined;
    readonly type: string | null | undefined;
  } | null | undefined> | null | undefined;
  readonly socialHeadline: string | null | undefined;
  readonly types: ReadonlyArray<{
    readonly value: ReadonlyArray<{
      readonly entityId: string;
    } | null | undefined> | null | undefined;
  } | null | undefined> | null | undefined;
  readonly urlAlias: string;
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckIsAmpEnabledArticle" | "helpersCheckIsPostMagazineArticle" | "helpersCheckIsPostiesArticle" | "helpersCheckIsStyleArticle" | "hooksContentHeadlineTagContent">;
  readonly " $fragmentType": "hooksArticleSeoArticle";
};
export type hooksArticleSeoArticle$key = {
  readonly " $data"?: hooksArticleSeoArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"hooksArticleSeoArticle">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "SectionSegment",
  "kind": "LinkedField",
  "name": "sections",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Section",
      "kind": "LinkedField",
      "name": "value",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "kind": "InlineDataFragmentSpread",
  "name": "helpersCheckIsPostiesArticle",
  "selections": (v3/*: any*/),
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
},
v5 = {
  "kind": "InlineDataFragmentSpread",
  "name": "helpersCheckIsStyleArticle",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v3/*: any*/),
      "type": "Content",
      "abstractKey": "__isContent"
    }
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "flag",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "ArticleTypeSegment",
  "kind": "LinkedField",
  "name": "types",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ArticleType",
      "kind": "LinkedField",
      "name": "value",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Author",
  "kind": "LinkedField",
  "name": "authors",
  "plural": true,
  "selections": (v1/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "hooksArticleSeoArticle",
  "selections": [
    (v4/*: any*/),
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersCheckIsPostMagazineArticle",
      "selections": (v3/*: any*/),
      "args": null,
      "argumentDefinitions": []
    },
    (v5/*: any*/),
    {
      "kind": "InlineDataFragmentSpread",
      "name": "hooksContentHeadlineTagContent",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ArticleTypeSegment",
                  "kind": "LinkedField",
                  "name": "types",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ArticleType",
                      "kind": "LinkedField",
                      "name": "value",
                      "plural": true,
                      "selections": [
                        (v6/*: any*/),
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "FormattedValue",
                          "kind": "LinkedField",
                          "name": "description",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "text",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v5/*: any*/)
              ],
              "type": "Article",
              "abstractKey": null
            },
            (v7/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "SectionSegment",
              "kind": "LinkedField",
              "name": "sections",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Section",
                  "kind": "LinkedField",
                  "name": "value",
                  "plural": true,
                  "selections": [
                    (v6/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "hooksContentHelperContent",
              "selections": [
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "helpersSanitizeArticleTypeEntityIdsArticle",
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v8/*: any*/)
                      ],
                      "type": "Article",
                      "abstractKey": null
                    }
                  ],
                  "args": null,
                  "argumentDefinitions": []
                },
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "helpersSanitizeAuthorTypesArticle",
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Author",
                          "kind": "LinkedField",
                          "name": "authors",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "types",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "Article",
                      "abstractKey": null
                    }
                  ],
                  "args": null,
                  "argumentDefinitions": []
                },
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "helpersSanitizedAuthorEntityIdsArticle",
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v9/*: any*/)
                      ],
                      "type": "Article",
                      "abstractKey": null
                    }
                  ],
                  "args": null,
                  "argumentDefinitions": []
                },
                (v7/*: any*/)
              ],
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "type": "Content",
          "abstractKey": "__isContent"
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersCheckIsAmpEnabledArticle",
      "selections": [
        (v0/*: any*/),
        (v8/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "disableOffPlatformContent",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sponsorType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "published",
          "storageKey": null
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "helpersCheckIsPlusArticle",
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PaywallType",
                      "kind": "LinkedField",
                      "name": "paywallTypes",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "entityUuid",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "Article",
                  "abstractKey": null
                }
              ],
              "type": "Content",
              "abstractKey": "__isContent"
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        (v4/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": []
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "headline",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "socialHeadline",
      "storageKey": null
    },
    (v8/*: any*/),
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "images",
      "plural": true,
      "selections": [
        {
          "alias": "generic_og",
          "args": [
            {
              "kind": "Literal",
              "name": "filter",
              "value": {
                "style": "og_image_scmp_generic"
              }
            }
          ],
          "concreteType": "ImageStyle",
          "kind": "LinkedField",
          "name": "style",
          "plural": false,
          "selections": [
            (v10/*: any*/)
          ],
          "storageKey": "style(filter:{\"style\":\"og_image_scmp_generic\"})"
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "filter",
              "value": {
                "styles": [
                  "og_image_posties_article",
                  "og_postmag",
                  "og_image_style",
                  "og_image_scmp_analysis",
                  "og_image_scmp_explainer",
                  "og_image_scmp_editorial",
                  "og_image_scmp_fact_check",
                  "og_image_scmp_debate",
                  "og_image_scmp_live",
                  "og_image_scmp_obituary",
                  "og_image_scmp_opinion",
                  "og_image_scmp_review",
                  "og_image_scmp_series",
                  "og_image_scmp_generic"
                ]
              }
            }
          ],
          "concreteType": "ImageStyle",
          "kind": "LinkedField",
          "name": "styles",
          "plural": true,
          "selections": [
            (v10/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "style",
              "storageKey": null
            }
          ],
          "storageKey": "styles(filter:{\"styles\":[\"og_image_posties_article\",\"og_postmag\",\"og_image_style\",\"og_image_scmp_analysis\",\"og_image_scmp_explainer\",\"og_image_scmp_editorial\",\"og_image_scmp_fact_check\",\"og_image_scmp_debate\",\"og_image_scmp_live\",\"og_image_scmp_obituary\",\"og_image_scmp_opinion\",\"og_image_scmp_review\",\"og_image_scmp_series\",\"og_image_scmp_generic\"]})"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "urlAlias",
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();

(node as any).hash = "d91d285daf4a1ffacffa0805d7bc8f95";

export default node;
