export const ExcludedArticleIds = [
  "1780152",
  "1781385",
  "1783122",
  "1782752",
  "1785202",
  "1786367",
  "1787941",
  "1788985",
  "1854626",
  "1855229",
  "1857242",
  "1862338",
  "1863178",
  "1863522",
  "1784494",
  "1815689",
  "1840168",
  "1840737",
  "1944555",
  "2057808",
  "1825585",
];
