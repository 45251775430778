/**
 * @generated SignedSource<<3196e949bac662e2c14a27c1ca455326>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type hooksContentInterestArticle$data = {
  readonly entityId: string;
  readonly sentiment: unknown | null | undefined;
  readonly topics: ReadonlyArray<{
    readonly name: string;
  } | null | undefined> | null | undefined;
  readonly types: ReadonlyArray<{
    readonly value: ReadonlyArray<{
      readonly name: string;
    } | null | undefined> | null | undefined;
  } | null | undefined> | null | undefined;
  readonly urlAlias: string;
  readonly " $fragmentType": "hooksContentInterestArticle";
};
export type hooksContentInterestArticle$key = {
  readonly " $data"?: hooksContentInterestArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"hooksContentInterestArticle">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "hooksContentInterestArticle"
};

(node as any).hash = "7e41a29c62eb7a0467f234077b14d019";

export default node;
