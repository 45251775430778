/**
 * @generated SignedSource<<e5d6ff050c06d9f251a8a78228c2c58e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type hooksTrackPageViewArticle$data = {
  readonly authors: ReadonlyArray<{
    readonly name: string;
  } | null | undefined> | null | undefined;
  readonly entityId: string;
  readonly entityUuid: string;
  readonly headline: string | null | undefined;
  readonly publishedDate: number | null | undefined;
  readonly sections: ReadonlyArray<{
    readonly value: ReadonlyArray<{
      readonly name: string;
    } | null | undefined> | null | undefined;
  } | null | undefined> | null | undefined;
  readonly urlAlias: string;
  readonly " $fragmentType": "hooksTrackPageViewArticle";
};
export type hooksTrackPageViewArticle$key = {
  readonly " $data"?: hooksTrackPageViewArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"hooksTrackPageViewArticle">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "hooksTrackPageViewArticle",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publishedDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "headline",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SectionSegment",
      "kind": "LinkedField",
      "name": "sections",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Section",
          "kind": "LinkedField",
          "name": "value",
          "plural": true,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "urlAlias",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Author",
      "kind": "LinkedField",
      "name": "authors",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityUuid",
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();

(node as any).hash = "9cafccb6ee3c6fa2c4f3283b07820e6a";

export default node;
