import type { FunctionComponent } from "react";

import { useEditionValue } from "shared/lib/edition";
import { useGetHomeAsPath } from "shared/lib/router";

import { page as HomePage } from "scmp-app/pages/home";

import {
  Container,
  ContentBody,
  ContentBodyText,
  ContentContainer,
  ContentFooter,
  StyledAnchor,
  StyledBaseLink,
  StyledLogo,
  StyledLogoFlag,
  StyledLogoText,
  Wrapper,
} from "./styles";

export type Props = {
  handleAcceptAcknowledgement: () => void;
};

export const ArticleAcknowledgementGateOverlay: FunctionComponent<Props> = ({
  handleAcceptAcknowledgement,
}) => {
  const edition = useEditionValue();
  const homeAsPath = useGetHomeAsPath();
  const { pathname: homePagePathname } = HomePage.route({
    edition,
    slide: "main",
  });

  return (
    <Container>
      <Wrapper>
        <ContentContainer>
          <StyledLogo as={homeAsPath} pathname={homePagePathname}>
            <StyledLogoFlag />
            <StyledLogoText />
          </StyledLogo>
          <ContentBody>
            <ContentBodyText>
              <b>Update:</b> A Canadian Tribunal has ruled that China’s allegations against Michael
              Ching have not been established, putting him on the path to Canadian citizenship.
            </ContentBodyText>
            <ContentBodyText>
              The article you searched was the subject of a defamation claim by Michael Ching
              against the SCMP, resolved by a settlement requiring readers to acknowledge subsequent
              Canadian developments.
            </ContentBodyText>
            <ContentBodyText>
              To read more about those developments, click{" "}
              <StyledAnchor href="https://sc.mp/acknowledge" target="_blank">
                here
              </StyledAnchor>
              .
            </ContentBodyText>
            <ContentBodyText>
              Click <StyledAnchor onClick={handleAcceptAcknowledgement}>here</StyledAnchor> to
              acknowledge you’ve read this update.
            </ContentBodyText>
          </ContentBody>
          <ContentFooter>
            <StyledBaseLink as={homeAsPath} pathname={homePagePathname}>
              Back to SCMP.com
            </StyledBaseLink>
          </ContentFooter>
        </ContentContainer>
      </Wrapper>
    </Container>
  );
};

ArticleAcknowledgementGateOverlay.displayName = "ArticleAcknowledgementGateOverlay";
