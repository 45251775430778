/**
 * @generated SignedSource<<5647ac7a102c11bff969cae657030bf8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type hooksArticleAcknowledgementGateOverlayArticle$data = {
  readonly entityId: string;
  readonly " $fragmentType": "hooksArticleAcknowledgementGateOverlayArticle";
};
export type hooksArticleAcknowledgementGateOverlayArticle$key = {
  readonly " $data"?: hooksArticleAcknowledgementGateOverlayArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"hooksArticleAcknowledgementGateOverlayArticle">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "hooksArticleAcknowledgementGateOverlayArticle",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "28aae103db47798c8e14564db5c5bd68";

export default node;
