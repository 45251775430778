import styled from "@emotion/styled";
import { fontRoboto, theme } from "@product/scmp-sdk";

import { BaseLink } from "shared/components/common/base-link";

import LogoFlag from "scmp-app/components/header/icon-logo-flag.svg";
import LogoText from "scmp-app/components/header/icon-logo-text.svg";

export const Container = styled.div`
  inset-block-start: 0;
  inset-inline-start: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #ffffff;
  ${theme.breakpoints.up("desktop")} {
    block-size: 70vh;
  }
`;

export const Wrapper = styled.div`
  padding-block: 20px;

  ${theme.breakpoints.up("tablet")} {
    margin-block-start: 40px;

    overflow-y: hidden;
  }
`;

export const ContentContainer = styled.div`
  padding: 24px;

  font-weight: normal;
  font-size: 16px;
  font-family: ${fontRoboto};
  line-height: 21px;

  border: 1px solid #e5e5e5;
  border-radius: 4px;

  ${theme.breakpoints.up("tablet")} {
    max-inline-size: 760px;
    padding: 40px;
  }
`;

export const ContentBody = styled.div``;

export const ContentBodyText = styled.p`
  margin-block-start: 21px;
`;

export const ContentFooter = styled.div`
  margin-block-start: 21px;
  padding-block-start: 32px;
  border-block-start: 1px solid #d8d8d8;
`;

export const StyledLogoText = styled(LogoText)`
  display: inline-block;

  margin-inline-start: 7px;

  ${theme.breakpoints.up("tablet")} {
    inline-size: 290px;
    block-size: 35px;
  }
`;

export const StyledLogoFlag = styled(LogoFlag)`
  display: inline-block;

  ${theme.breakpoints.up("tablet")} {
    inline-size: 45px;
    block-size: 35px;
  }
`;

export const StyledLogo = styled(BaseLink)`
  display: flex;
  align-items: center;
`;

export const StyledAnchor = styled.a`
  color: #4585ff;

  cursor: pointer;
`;

export const StyledBaseLink = styled(BaseLink)`
  color: #4585ff;
`;
