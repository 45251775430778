// CSpell:ignore articleid amphtml
import { findFirstNonEmptyString } from "@product/scmp-sdk";
import first from "lodash/first";
import type { NextSeoProps } from "next-seo";
import type { FunctionComponent } from "react";
import { useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { defaultAdditionalMetaTags } from "shared/components/meta";
import { useKeywordMetaTags } from "shared/components/meta/hooks";
import { article as articleData } from "shared/data/article";
import { formatInTimeZone } from "shared/lib/utils";

import { getArticleType } from "scmp-app/components/article/article-render/helpers";
import { checkIsPostiesArticle } from "scmp-app/components/article/article-render/posties/helpers";
import { PageSeo } from "scmp-app/components/meta";
import { PostiesAdditionalLinkTags } from "scmp-app/components/posties/posties-seo/consts";
import type { articleSeoArticle$key } from "scmp-app/queries/__generated__/articleSeoArticle.graphql";

import { ExcludedArticleIds } from "./consts";
import { useArticleSeoHelper } from "./hooks";

type Props = {
  extraSeoOptions?: NextSeoProps;
  reference: articleSeoArticle$key;
};

export const ArticleSeo: FunctionComponent<Props> = ({
  extraSeoOptions = {},
  reference: reference_,
}) => {
  const article = useFragment(
    graphql`
      fragment articleSeoArticle on Article
      @argumentDefinitions(
        customContents: { type: "[CustomContentInput]", defaultValue: [] }
        bodyType: { type: "BodyFormatTypeEnum", defaultValue: PARAGRAPH }
      ) {
        ...hooksArticleSeoArticle
        ...helpersCheckIsPostiesArticle
        ...helpersGetArticleTypeArticle
        entityId
        publishedDate
        updatedDate
        summary {
          text
        }
        subHeadline {
          text
        }
        body(customContents: $customContents, type: $bodyType) {
          text
        }
        keywords
        sections {
          value {
            name
          }
        }
        topics {
          name
          entityId
        }
        urlAlias
        authors {
          name
        }
        images {
          style(filter: { style: "768x768" }) {
            url
          }
        }
        readingTime
      }
    `,
    reference_,
  );

  const { canonicalUrl, computedMetaHeadline, isAmpMetaTagRequired, openGraphMetaData } =
    useArticleSeoHelper(article);

  const pickedSummary = first(article.summary?.text?.split("\n"));
  const pickedSubHeadline = first(article.subHeadline?.text?.split("\n"));
  const pickedBody = first(article.body?.text?.split("\n"));

  const sanitizedDescription = findFirstNonEmptyString(
    pickedSummary,
    pickedSubHeadline,
    pickedBody,
  );

  const sanitizedKeywords = article.keywords?.join(",") ?? "";

  const isPostiesArticle = useMemo(() => checkIsPostiesArticle(article), [article]);

  const keywordTags = useKeywordMetaTags(
    sanitizedKeywords.length > 0 ? sanitizedKeywords : undefined,
  );

  const publishDate = article.publishedDate
    ? /* Cspell: disable-next-line */
      formatInTimeZone(article.publishedDate, "yyyy-MM-dd'T'HH:mm:ssXXX")
    : "";

  const updatedDate = article.updatedDate
    ? /* Cspell: disable-next-line */
      formatInTimeZone(article.updatedDate, "yyyy-MM-dd'T'HH:mm:ssXXX")
    : publishDate;

  const dates = [
    {
      content: publishDate,
      property: "article:published_time",
    },
    {
      content: updatedDate,
      property: "article:modified_time",
    },
  ];

  const readingTime = article.readingTime?.toString() ?? "0";

  const currentArticleType = getArticleType(article);
  const readingTimeMetaData = useMemo(() => {
    if (
      currentArticleType === articleData.types.newsletter.entityId ||
      currentArticleType === articleData.types.live.entityId ||
      currentArticleType === articleData.types.series.entityId
    ) {
      return [];
    }
    return [
      {
        content: "Reading time",
        name: "twitter:label1",
      },
      {
        content: `${readingTime} min read`,
        name: "twitter:data1",
      },
    ];
  }, [currentArticleType, readingTime]);

  const sanitizedSection =
    article.sections
      ?.flatMap(section => section?.value)
      .map((section, index) => ({
        content: section?.name ?? "",
        keyOverride: `tag${index}`,
        property: "article:section",
      })) ?? [];

  const sanitizedTags =
    article.topics?.map((topic, index) => ({
      content: topic?.name ?? "",
      keyOverride: `tag-${index}`,
      property: "article:tag",
    })) ?? [];

  const cXenseMetaTags = [
    {
      content: article.entityId,
      name: "cXenseParse:articleid",
    },
    {
      content: first(article.authors)?.name ?? "",
      name: "cXenseParse:author",
    },
    {
      content: first(article.images)?.style?.url ?? "",
      property: "cXenseParse:image",
    },
  ];

  const mobileContentArticleUrl = `scmp:/${article.urlAlias}?entity=node&type=article&id=${article.entityId}`;

  const mobileAppMetaData = [
    {
      content: mobileContentArticleUrl,
      property: "al:ios:url",
    },
    {
      content: mobileContentArticleUrl,
      property: "al:android:url",
    },
  ];

  const isRobotsNoIndex =
    ExcludedArticleIds.includes(article.entityId) || !!extraSeoOptions?.noindex;

  const linkTags = [];

  if (isAmpMetaTagRequired) {
    linkTags.push({
      href: `https://amp.scmp.com${article.urlAlias}`,
      rel: "amphtml",
    });
  }

  if (isPostiesArticle) {
    linkTags.push(...PostiesAdditionalLinkTags);
  }

  return (
    <PageSeo
      {...extraSeoOptions}
      additionalLinkTags={[...linkTags]}
      additionalMetaTags={[
        ...(isPostiesArticle ? [] : keywordTags),
        ...sanitizedSection,
        ...sanitizedTags,
        ...dates,
        ...defaultAdditionalMetaTags,
        ...cXenseMetaTags,
        ...mobileAppMetaData,
        ...readingTimeMetaData,
      ]}
      canonical={canonicalUrl}
      description={sanitizedDescription}
      noindex={isRobotsNoIndex}
      openGraph={{
        ...openGraphMetaData,
      }}
      options={{
        customMerge(key) {
          if (isPostiesArticle && (key === "additionalLinkTags" || key === "additionalMetaTags")) {
            return (_, source: unknown[]) => source;
          }
        },
      }}
      title={computedMetaHeadline}
      twitter={isPostiesArticle ? undefined : {}}
    />
  );
};

ArticleSeo.displayName = "ArticleSeo";
