/**
 * @generated SignedSource<<3ddcf9a5a00e644902e44475f09d09cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type helpersCheckIsAmpEnabledArticle$data = {
  readonly disableOffPlatformContent: ReadonlyArray<string | null | undefined> | null | undefined;
  readonly entityId: string;
  readonly published: boolean;
  readonly sections: ReadonlyArray<{
    readonly value: ReadonlyArray<{
      readonly entityId: string;
    } | null | undefined> | null | undefined;
  } | null | undefined> | null | undefined;
  readonly sponsorType: string | null | undefined;
  readonly types: ReadonlyArray<{
    readonly value: ReadonlyArray<{
      readonly entityId: string;
    } | null | undefined> | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckIsPlusArticle" | "helpersCheckIsPostiesArticle">;
  readonly " $fragmentType": "helpersCheckIsAmpEnabledArticle";
};
export type helpersCheckIsAmpEnabledArticle$key = {
  readonly " $data"?: helpersCheckIsAmpEnabledArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"helpersCheckIsAmpEnabledArticle">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "helpersCheckIsAmpEnabledArticle"
};

(node as any).hash = "0048328f7e97a4747b6aca6bdd082b9a";

export default node;
