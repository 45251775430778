export const UrlSectionNameMap: Record<string, string> = {
  "/better-life": "Better Life",
  "/business": "Business",
  "/comment": "Comment",
  "/cooking": "Cooking",
  "/culture": "Culture",
  "/economy": "Economy",
  "/lifestyle": "Lifestyle",
  "/magazines/style": "Style",
  "/news/asia": "Asia news",
  "/news/china": "China news",
  "/news/hong-kong": "HK news",
  "/news/people-culture": "People & Culture",
  "/news/world": "World news",
  "/postMag": "Postmag",
  "/property": "Property",
  "/sport": "Sport",
  "/tech": "Tech",
  "/video": "Video",
  "/week-asia": "This week in Asia",
  "/yp": "Young Post",
};
